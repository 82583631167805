<template>
	<div>
		<div class="a-profile-subpage-title -certificates">
			<div>
				<h1 class="w-color-orange-diploms">Diplomy</h1>
				<p class="w-color-black">Zoznam diplomov</p>
			</div>
			<div class="z-icon-wrapper -large">
				<svg-ribbon-icon class="w-color-orange-diploms -ribbon" />
			</div>
		</div>
		<b-tabs>
			<b-tab title="Zoznam diplomov" active>
				<z-certificate-table
					v-if="certificates && certificates.length" @itemToDownload="downloadItem"
					:tableFields="fields" :tableItems="certificates"
					class="mt-5"
				/>

				<div v-else-if="certificates && !certificates.length" class="d-flex justify-content-center m-5">
					<div class="m-5 text-center">
						<h5>
							{{ isAdmin ? 'Keď úspešne zvládnete ľubovoľnú Sériu, môžete získať diplom' : 'Keď úspešne zvládneš ľubovoľnú Sériu, môžeš získať diplom' }}
						</h5>
						<b-button
							class="mt-3" type="button" pill size="sm" variant="primary mb-0"
							@click="$router.push('/kurzy')"
						>
							Pozrieť Série
						</b-button>
					</div>
				</div>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import { sortBy } from 'lodash'
import { mapGetters } from 'vuex'
import wAxios from '@/plugins/w/axios'
import { downloadCertificate } from './lib/downloadCertificate'

const api = {
	getCertificates: () => wAxios.get_auth_data(`v1/certificates`)
}

export default {
	layout: 'profile',

	components: {
		'z-certificate-table':		() => import('@/plugins/appzmudri@profile/certificates/z-certificate-table.vue'),
		'svg-ribbon-icon':		() => import('@/plugins/appzmudri/_theme/icon/ribbon.svg?inline'),
	},

	data() {
		return {
			fields: [
				{key: 'completed_path', label: 'Absolvované série', class: 'completed-path'},
				{key: 'issued_at', label: 'Dátum získania', class: 'issued-at'},
				{key: 'download', label: '', class: 'download'}
			],
			certificates: null
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user && this.user.profile && this.user.profile.type == 'teacher'
		},
	},

	async created() {
		await this._loadCertificates()
	},

	methods: {
		async _loadCertificates() {
			this.certificates = await api.getCertificates()
			this.certificates = this.certificates.map((certificate, index) => {
				certificate._idx = index
				return certificate
			})
			this.certificates  = sortBy(this.certificates, e => e.issued_at)
		},
		async downloadItem(idx) {
			const certificate = this.certificates[idx]

			downloadCertificate(certificate)
		}
	}
}
</script>

<style lang="scss" scoped>
	.svg-pointer {
		cursor: pointer;
	}
</style>